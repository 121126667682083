import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, __RouterContext } from 'react-router-dom';

import { escapeRegExp } from 'dpl/shared/utils';

/**
 * Infer whether we are w/in a react-router basename context and returns the
 * correct pathname. ie a "correct" relative path w/in the dashboard is
 * `/breeder/dashboard/overview`, but when we are inside the context of the
 * 'breeder' basename, the path react-router needs is just
 * `/dashboard/overview`.
 */
export function getPathWithinReactRouter(
  originalPath,
  currentReactRouterPath,
  currentFullPath = window.location.pathname
) {
  const routeRegex = new RegExp(
    `^(.+?)${escapeRegExp(currentReactRouterPath)}`
  );
  const [, basename] = currentFullPath.match(routeRegex) || [];

  return basename ? originalPath.replace(basename, '') : originalPath;
}

/**
 * Toggles between rendering a react-router link vs a "real" anchor tag based
 * on whether it was rendered w/in a react-router context or not.
 */
export default function SmartLink({ dataTestId, to, ...props }) {
  const router = useContext(__RouterContext);
  if (!router || /^(https?|mailto):/i.test(to)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} href={to} data-test-id={dataTestId} />;
  }

  if (!to) {
    to = '#';
    const _onClick = props.onClick;
    props.onClick = e => {
      e.preventDefault();
      return _onClick && _onClick(e);
    };
  } else {
    to = getPathWithinReactRouter(to, router.history?.location?.pathname);
  }

  return <Link {...props} to={to} data-test-id={dataTestId} />;
}

SmartLink.contextTypes = {
  router: PropTypes.object
};

SmartLink.propTypes = {
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string
};

SmartLink.defaultProps = {
  dataTestId: 'smart-link',
  onClick: null,
  to: null
};
